import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiHeader = _resolveComponent("ApiHeader")!
  const _component_Gamelist = _resolveComponent("Gamelist")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ApiHeader, {
      url: '',
      method: "GET",
      type: "live",
      noaddress: true
    }),
    _createVNode(_component_Gamelist, {
      type: "live",
      header: _ctx.liveHeader,
      data: _ctx.liveData,
      class: "mt-4"
    }, null, 8, ["header", "data"])
  ], 64))
}