import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0" }
const _hoisted_3 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Datatable, {
        "table-data": _ctx.data,
        "table-header": _ctx.header,
        "enable-items-per-page-dropdown": true
      }, {
        "cell-slotgamelist": _withCtx(({ row: list }) => [
          (list.slotgamelist)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("ApiManual.Exist")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_4))
        ]),
        _: 1
      }, 8, ["table-data", "table-header"])
    ])
  ]))
}