
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Gamelist from "@/components/manual/Gamelist.vue";
import ApiHeader from "@/components/manual/ApiHeader.vue";

export default defineComponent({
  name: "live-gamelist",
  components: {
    ApiHeader,
    Gamelist,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("ApiManual.Gamelist"), [
        t("ApiManual.RealtimeCasino"),
      ]);
    });

    const liveHeader = [
      {
        name: t("Game.Game"),
        key: "name",
        text: true,
        sortable: true,
      },
      {
        name: t("Game.GameCode"),
        key: "gamecode",
        text: true,
        sortable: true,
      },
    ];

    const liveData = [{ gamecode: "CMB", name: t("Game.CMB") }];

    return {
      liveHeader,
      liveData,
    };
  },
});
