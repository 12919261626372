
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "gamelist",
  components: {
    Datatable,
  },
  props: {
    type: { type: String, required: true },
    header: { type: Array, required: true },
    data: { type: Array, required: true },
  },
});
